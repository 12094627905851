import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  InputNumber,
  TreeSelect,
  Popover,
  // Statistic,
  Table,
  notification,
  Select,
} from "antd";
import useData from "../../hooks/useData";
import NumberFormat from "../../utils/NumberFormat";
import * as Icon from "react-feather";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "feather-icons-react/build/IconComponents";
import { all_routes } from "../../Router/all_routes";

const buildTreeData = (categories, products, language) => {
  // Создаем объект для быстрого поиска категорий по _id
  const categoryMap = categories.reduce((map, category) => {
    map[category._id] = { ...category, children: [] };
    return map;
  }, {});

  // Добавляем продукты к соответствующим категориям
  products.forEach((product) => {
    if (categoryMap[product.category]) {
      categoryMap[product.category].children.push({
        ...product,
        checkable: true,
        searchContext: `${product.name} ${product?.code}`,
        title: (
          <>
            {product?.file ? (
              <Popover
                zIndex={3000}
                placement={"right"}
                content={
                  <>
                    <img
                      src={product.file}
                      width={200}
                      height={200}
                      style={{
                        background: "#FFF",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </>
                }
              >
                {product.name} {product?.code}
              </Popover>
            ) : (
              <div>
                {product.name} {product?.code}
              </div>
            )}
          </>
        ),
        value: product._id,
        unitName: product.unitName,
        quantity: 0,
      });
    }
  });

  Object.values(categoryMap).forEach((category) => {
    if (category.parent && categoryMap[category.parent]) {
      categoryMap[category.parent].children.push({
        checkable: false,
        searchContext: category.name[language],
        title: category.name[language], // Используем название категории
        value: category._id,
        children: category.children, // Подкатегории или продукты
      });
    }
  });

  const treeData = Object.values(categoryMap)
    .filter((category) => !category.parent && category.children.length > 0)
    .map((category) => ({
      title: category.name[language], // или другой язык в зависимости от предпочтений
      searchContext: category.name[language],
      value: category._id,
      children: category.children,
      checkable: false,
    }));

  return treeData;
};

const calculateCostsByCategory = (categories, products, selectedProducts) => {
  const costsByCategory = {};

  // Функция для поиска всех родительских категорий
  const getCategoryHierarchy = (categoryId) => {
    const category = categories.find((c) => c._id === categoryId);
    if (category?.parent) {
      return [...getCategoryHierarchy(category.parent), category];
    }
    return category ? [category] : [];
  };

  // Функция для получения множителя категории
  const getCategoryMultiplier = (categoryHierarchy) => {
    // Ищем наценку по иерархии категорий
    for (let i = categoryHierarchy.length - 1; i >= 0; i--) {
      if (categoryHierarchy[i].multiple) {
        return categoryHierarchy[i].multiple;
      }
    }
    // Если нигде нет множителя, то возвращаем дефолтное значение 2
    return 2;
  };

  selectedProducts.forEach((selectedProduct) => {
    // Находим информацию о продукте
    const product = products.find((p) => p._id === selectedProduct.value);
    if (!product) return;

    // Находим иерархию категорий продукта
    const categoryHierarchy = getCategoryHierarchy(product.category);

    // Количество продукта, если указано
    const quantity = selectedProduct.quantity || 1;

    // Расчет себестоимости
    const productCost =
      selectedProduct.unit === "66428b8c5a60f8cde1e5cb1b"
        ? product?.price * quantity
        : (product.price * quantity) / (product?.value || 1);

    // Определяем наценку
    const multiplier = getCategoryMultiplier(categoryHierarchy);

    // Перебираем все категории в иерархии, начиная с корневой
    let currentLevel = costsByCategory;
    categoryHierarchy.forEach((category, index) => {
      const categoryName = category?.name?.ru || "Неизвестная категория";

      if (!currentLevel[categoryName]) {
        currentLevel[categoryName] = {
          id: category?._id,
          name: categoryName,
          total: 0,
          sellPrice: 0,
          children: {},
        };
      }

      if (index === categoryHierarchy.length - 1) {
        // Если это конечная категория, добавляем затраты и цену продажи
        currentLevel[categoryName].total += productCost;
        currentLevel[categoryName].sellPrice += productCost * multiplier;
      }

      // Переходим на следующий уровень
      currentLevel = currentLevel[categoryName].children;
    });
  });

  // Рекурсивно вычисляем сумму для каждой категории с учетом вложенных
  const calculateTotalWithChildren = (category) => {
    let total = category.total;
    let sellPrice = category.sellPrice;
    if (category.children) {
      Object.values(category.children).forEach((child) => {
        total += calculateTotalWithChildren(child).total;
        sellPrice += calculateTotalWithChildren(child).sellPrice;
      });
    }
    return { total, sellPrice };
  };

  // Преобразуем объект в массив для удобного отображения и пересчитываем сумму
  const transformToArray = (obj) => {
    return Object.values(obj).map((item) => {
      const { total, sellPrice } = calculateTotalWithChildren(item);
      const obj1 = {
        key: item?.id,
        id: item?.id,
        name: item.name,
        total, // Себестоимость
        sellPrice, // Цена продажи с учетом наценки
      };
      if (Object.values(item.children).length) {
        obj1.children = transformToArray(item.children);
      }
      return obj1;
    });
  };

  return transformToArray(costsByCategory);
};

const ProductCalculator = () => {
  const { id } = useParams();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [name, setName] = useState("");
  const [contract, setContract] = useState("");
  const { data: products } = useData("/products/products", []);
  const { data: units } = useData("/products/units", []);
  const { data: categories } = useData("/products/categories", []);
  const { data: attributes } = useData("/products/attributes", []);
  const { data: initialData, update } = useData(
    `/products/calc${id ? `?id=${id}` : ""}`,
    null,
  );

  const getUnitName = (unitId) => {
    const unit = units?.find((unit) => unit._id === unitId);
    return unit ? unit.name[language] : "N/A";
  };

  useEffect(() => {
    if (
      initialData &&
      products.length &&
      categories.length &&
      attributes.length
    ) {
      setSelectedProducts(initialData?.selectedProducts || []);
      setName(initialData?.name || "");
      setContract(initialData?.contract || "");
    }
  }, [attributes, categories, initialData, products]);
  const [adding, setAdding] = useState(false);
  const [api, contextHolder] = notification.useNotification({
    duration: 2,
  });
  const handleSubmit = async () => {
    setAdding(true);
    const data = selectedProducts.map((p) =>
      JSON.parse(
        JSON.stringify({
          value: p.value,
          quantity: p.quantity || 1,
          unit: p?.unit,
          unitName: p?.unitName,
        }),
      ),
    );
    const response = await update({
      id,
      name,
      contract: contract || "",
      selectedProducts: data,
    });
    if (response?.success) {
      api.open({
        message: "Успешно",
        type: "success",
        description: "Калькулация успешно сохранена",
      });
    }
    setAdding(false);
  };

  const getAttributeNameById = (attributeId) => {
    const attribute = attributes?.find(
      (attribute) => attribute._id === attributeId,
    );
    return attribute ? attribute.name[language] : "N/A";
  };

  const getAttributeListByIds = (attributes) => {
    return attributes ? (
      <ul>
        {Object.entries(attributes).map(([key, value]) => (
          <li key={key}>
            {getAttributeNameById(key)}: <strong>{value}</strong>
          </li>
        ))}
      </ul>
    ) : (
      "-"
    );
  };
  const {
    i18n: { t, language },
  } = useTranslation();

  const handleQuantityChange = (id, quantity) => {
    if (!id) return;
    const product = selectedProducts.find((p) => p.value === id);
    product.quantity = quantity;
    setSelectedProducts([...selectedProducts]);
  };

  const handleChangeUnit = (id, unit) => {
    if (!id) return;
    const product = selectedProducts.find((p) => p.value === id);
    product.unit = unit;
    product.unitName = getUnitName(unit);
    setSelectedProducts([...selectedProducts]);
  };

  const handleProductSelect = (e) => {
    setSelectedProducts(
      e.map((v) => {
        const product = selectedProducts?.find((p) => p.value === v.value);
        return { ...v, quantity: product?.quantity };
      }),
    );
  };

  // const calculateTotal = () => {
  //   return selectedProducts.reduce((total, pr) => {
  //     const product = products?.find((p) => p._id === pr.value);
  //
  //     return (
  //       total +
  //       ((pr?.quantity || 1) * (product?.price || 1)) /
  //         (!isNaN(product.value) ? product.value : 1)
  //     );
  //   }, 0);
  // };

  const treeData = buildTreeData(categories, products, language);
  const ccc = calculateCostsByCategory(
    categories,
    products,
    selectedProducts,
    // products.filter((pr) => selectedProducts.find((s) => s.value === pr._id)),
  );

  const unitSelector = (product, selected = null) => {
    if (product?.unit === "66428b8c5a60f8cde1e5cb1b") {
      return product.unitName[language];
    }

    return (
      <Select
        defaultValue={selected || product.unit}
        style={{ minWidth: 80 }}
        onSelect={(v) => handleChangeUnit(product?._id, v)}
      >
        <Select.Option value={product.unit}>
          {product.unitName[language]}
        </Select.Option>
        <Select.Option value={"66428b8c5a60f8cde1e5cb1b"}>
          {getUnitName("66428b8c5a60f8cde1e5cb1b")}
        </Select.Option>
      </Select>
    );
  };

  const { data: list } = useData("/sales/contracts");

  return (
    <div className="page-wrapper">
      {contextHolder}
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>{t("nav.Product Calculator")}</h4>
            </div>
          </div>
          <ul className="table-top-head">
            <li>
              <div className="page-btn">
                <Link to={all_routes.productcalc} className="btn btn-secondary">
                  <ArrowLeft className="me-2" />К списку
                </Link>
              </div>
            </li>
          </ul>
        </div>
        {/* /product list */}
        <div className="card table-list-card" style={{ padding: 12 }}>
          <div className="card-body">
            <div className={"col-lg-12"}>
              <div className={"mb-3"}>
                <label className="form-label">Название</label>
                <input
                  className={"form-control"}
                  value={name}
                  onChange={(v) => setName(v.target.value)}
                />
              </div>
            </div>
            <div className={"col-lg-12"}>
              <div className={"mb-3"}>
                <label className={"form-label"}>Выберите контракт</label>
                <Select
                  value={contract || undefined}
                  allowClear
                  style={{ width: "100%" }}
                  showSearch
                  placeholder={"Выберите контракт"}
                  options={list?.map((v) => {
                    return {
                      label: `${v.id} - ${v.userData[language]?.name || v.userData["ka"].name}`,
                      value: v._id,
                    };
                  })}
                  onChange={(v) => setContract(v)}
                />
              </div>
            </div>
            <label>Выберите продукцию</label>
            <TreeSelect
              multiple
              size={"large"}
              treeData={treeData}
              // options={products?.map((product) => ({
              //   label: product.name,
              //   value: product._id,
              // }))}
              value={selectedProducts.map((p) => p.value)}
              filterTreeNode={(input, treeNode) =>
                treeNode.searchContext
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              treeExpandAction={"click"}
              treeLine
              showSearch
              treeCheckable
              treeCheckStrictly
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              style={{ width: "100%" }}
              onChange={(v) => handleProductSelect(v)}
            />

            <div className={"table-responsive"}>
              <table
                border="1"
                style={{ marginTop: "20px" }}
                className={"table table-bordered"}
              >
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Название</th>
                    <th>Количество</th>
                    <th>Стоимость за единицу</th>
                    <th>Стоимость за комплект</th>
                    <th>Общая стоимость</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts?.map((pr, index) => {
                    const product = products?.find((p) => p._id === pr.value);
                    const quantity = pr?.quantity || 1;
                    const price =
                      pr?.unit === "66428b8c5a60f8cde1e5cb1b"
                        ? product?.price
                        : product?.price / (product?.value || 1);

                    return (
                      <tr key={product?._id}>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Image
                              alt=""
                              src={product?.file}
                              width={30}
                              height={30}
                              style={{
                                background: "#FFF",
                                borderRadius: 100,
                              }}
                            />
                            <div style={{ margin: "0 12px" }}>
                              {product?.name}{" "}
                            </div>
                            {Object.keys(product?.attributes)?.length ? (
                              <Popover
                                content={getAttributeListByIds(
                                  product?.attributes,
                                )}
                                trigger={"hover"}
                              >
                                <Icon.Info size={16} />
                              </Popover>
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <InputNumber
                            addonAfter={unitSelector(product, pr?.unit)}
                            value={quantity || 1}
                            onChange={(v) =>
                              handleQuantityChange(product._id, v)
                            }
                          />
                        </td>
                        <td>
                          {NumberFormat(price)}₾ -{" "}
                          <sup>
                            {pr?.unitName || product?.unitName[language]}
                          </sup>
                        </td>
                        <td>
                          {NumberFormat(
                            !isNaN(product?.price) ? product?.price : 0,
                          )}{" "}
                          ₾
                        </td>
                        <td>{NumberFormat(quantity * price)} ₾</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="row" style={{ padding: 24 }}>
              <Table
                dataSource={ccc}
                columns={[
                  {
                    title: "Категория",
                    dataIndex: "name",
                    key: "name",
                  },
                  {
                    title: "Себестоимость",
                    dataIndex: "total",
                    key: "total",
                    render: (t) => `${NumberFormat(t)} ₾`,
                  },
                  {
                    title: "Цена",
                    dataIndex: "sellPrice",
                    key: "sellPrice",
                    render: (t) => `${NumberFormat(t)} ₾`,
                  },
                ]}
                footer={() => {
                  const total = ccc.reduce((acc, v) => {
                    return acc + v.total;
                  }, 0);

                  return <h4>Итого себестоимость: {NumberFormat(total)} ₾</h4>;
                }}
              ></Table>
              <div className="col-lg-12 mt-4">
                <div className="btn-addproduct mb-4">
                  <button
                    type={"button"}
                    className="btn btn-submit"
                    disabled={adding || selectedProducts.length === 0}
                    onClick={handleSubmit}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
              {/*  <div className="col-lg-4">*/}
              {/*    <Statistic*/}
              {/*      title="Себестоимость"*/}
              {/*      value={NumberFormat(calculateTotal())}*/}
              {/*      suffix={"₾"}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="col-lg-4">*/}
              {/*    <Statistic*/}
              {/*      title="С наценкой"*/}
              {/*      value={NumberFormat(calculateTotal() * 2 * 1.35)}*/}
              {/*      suffix={"₾"}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="col-lg-4">*/}
              {/*    <Statistic*/}
              {/*      title="Зарплатный фонд"*/}
              {/*      value={NumberFormat(*/}
              {/*        ((calculateTotal() * 2 * 1.35) / 1.18) * 0.3,*/}
              {/*      )}*/}
              {/*      suffix={"₾"}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="col-lg-4">*/}
              {/*    <Statistic*/}
              {/*      title="Прибыль"*/}
              {/*      value={NumberFormat(*/}
              {/*        ((calculateTotal() * 2 * 1.35) / 1.18) * 0.3,*/}
              {/*      )}*/}
              {/*      suffix={"₾"}*/}
              {/*    />*/}
              {/*  </div>*/}
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default ProductCalculator;
